import {messages as messagesDE} from "./de";
import {messages as messagesEN} from "./en";

export const translations: { [key: string]: any } = {
  "de-DE": {
    name: "Deutsch",
    messages: messagesDE,
  },
  "en-US": {
    name: "English",
    messages: messagesEN,
  },
};
