import React, {useEffect} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {Box, Button, ThemeProvider,} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {IntlProvider} from "react-intl";
import TuneIcon from "@mui/icons-material/TuneRounded";
import {darkTheme} from "./themes/default";
import "./App.css";
import Logo from "../src/resources/logo.svg";
import {translations} from "./translations";
import {Error} from "./screens/error/Error";
import {purgeStore,
        selectAuthenticationToken} from "./screens/authentication/authenticationSlice";
import {Authentication} from "./screens/authentication/Authentication";
import {useDispatch, useSelector} from "react-redux";
import {Rooms} from "./screens/rooms/Rooms";
import {AdminCenter} from "./screens/admin_center/AdminCenter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
  },
  spacer: {
    flex: 1,
  },
  routerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  appBar: {
    minHeight: 36,
    padding: 0,
  },
  listItemIcon: {
    marginLeft: 4,
  },
  navigationBar: {
    color: "#ffffff",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 20,
  },
  navigationTitle: {
    marginBottom: 20,
    "@media (max-height: 600px)": {
      display: "none",
    },
  },
  indicator: {
    display: "none",
  },
  illustration: {
    position: "absolute",
    top: 50,
    right: 40,
    transform: "scaleX(-1)",
    zIndex: 10000,
    "@media (max-width: 768px)": {
      display: "none",
    },
    "@media (max-height: 600px)": {
      display: "none",
    },
  },
  logo: {
    height: 15,
  },
  logoRow: {
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    marginTop: 26,
  },
  highlightedMask: {
    borderRadius: 10,
  },
  queueVisible: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 10200,
    transition: "right 1s",
  },
  queueInvisible: {
    position: "fixed",
    bottom: 20,
    right: -340,
    zIndex: 10200,
    transition: "right 1s",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    height: 68,
  },
  headerTopContainer: {
    height: 100,
    marginBottom: 40,
  },
  expandSidebarIcon: {
    transition: "transform 0.5s",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 44,
    overflow: "hidden",
  },
  logoTypography: {
    color: "#ffffff",
    letterSpacing: "0.02857em",
  },
  button: {
    color: "#ffffff",
  },
}));

const history = createBrowserHistory();

function App() {

  const dispatch = useDispatch();
  const authenticationToken = useSelector(selectAuthenticationToken);
  //const authenticationError = useSelector(selectError);
  //const authenticationFetching = useSelector(selectIsFetching);

  let theme = darkTheme;

  const classes = useStyles();

  // TODO: enable language selection
    const messages = translations["en-US"].messages;


  const AuthenticationContainer = ({ children }: { children: any }) => {

    const authenticationToken = useSelector(selectAuthenticationToken);

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("next", window.location.pathname);

    return authenticationToken ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: "/app.rbm/login/",
          search: urlSearchParams.toString(),
        }}
      />
    );
  };

//for the moment: comment out all other menu options
  const routes = [
    {
      label: "Rooms",
      path: "/app.rbm/rooms",
      icon: <TuneIcon />,
      component: Rooms,
    },
/*
    {
      label: "Manage",
      path: "/app.rbm/manage",
      icon: <TuneIcon />,
      component: AdminCenter,
    },
    */
  ];

  useEffect(() => {
  }, []);


  const logout = () => {
    dispatch(purgeStore());
    history.push({
      pathname: "/app.rbm/login/",
      search: window.location.search,
    });
  };

  return (
    <div className="App">
      <IntlProvider
        messages={messages}
        locale={"en-US"}
        defaultLocale="en-US"
        onError={() => undefined}
      >
        <ThemeProvider theme={theme}>
            <Box className={classes.contentContainer}>
              <Box
                className={classes.header}
                style={{ borderBottom: "1px solid #2D3038" }}
              >
                <img
                  alt="logo"
                  className={classes.logo}
                  src={Logo}
                  style={{ marginLeft: 10 }}
                />
                <Box className={classes.spacer} />
                <Button
                  color="lightColour"
                  style={{ padding: 10 }}
                  onClick={() => logout()}
                  >
                    Logout
                </Button>
              </Box>

              <main className={classes.routerContainer}>
                <Router history={history}>
                  <Switch>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        exact
                        path={route.path}
                        render={() => {
                          return (
                            <AuthenticationContainer>
                              <route.component />
                            </AuthenticationContainer>
                          );
                        }}
                      />
                    ))}
                    <Route
                      path="/app.rbm/login/"
                      render={() => {
                        const urlSearchParams = new URLSearchParams(
                          window.location.search
                        );
                        const redirectURL = urlSearchParams.get("next") || "";

                        urlSearchParams.delete("next");

                        return authenticationToken ? (
                          <Redirect
                            to={{
                              pathname: redirectURL
                                ? redirectURL
                                : "/app.rbm/rooms/",
                              search: urlSearchParams.toString(),
                            }}
                          />
                        )
                      :
                        (
                          <Authentication />
                        );
                      }}
                    />
                    <Route path="/error/" component={Error} />
                    <Redirect
                      from="/"
                      to={{
                        pathname: routes[0].path,
                        search: history.location.search,
                      }}
                    />
                  </Switch>
                </Router>
              </main>
            </Box>
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
